// variables
$white-color: #fff;
$primary-color: #005eff;
$text-color: #6F6F6F;
$canary-yellow: #ffe200;
$nickle-yellow: #ffec9a;
$ballet-pink: #ffa0de;
$sunset-red: #ff534f;
$salmon-red: #ff9998;
$coral-red: #ff7c7d;
$ocean-blue: #001f82;
$denim-blue: #005eef;
$sky-blue: #47c8ff;
$glass-blue: #77ddf5;
$royal-purple: #7705bc;
$blush-pink: #ffbde7;

// breakpoints
$small-mobile: 376px;
$mobile: 576px;
$small-tab: 768px;
$big-tab: 992px;
$desktop: 1200px;
$mid-desktop: 1400px;
$large-desktop: 1700px;
