// © CopyWrite IDEAMAGIX 

@import '_variables';

// fonts
@font-face {
    font-family: 'UnileverShilling';
    src: url('../fonts/UnileverShilling.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'UnileverShilling';
    src: url('../fonts/UnileverShillingItalic.ttf') format('truetype');
    font-weight: 400;
    font-style: italic;
}

@font-face {
    font-family: 'UnileverShilling';
    src: url('../fonts/UnileverShillingMedium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'UnileverShilling';
    src: url('../fonts/UnileverShillingBold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
}

// mixins
@mixin small-mobile {
    @media (min-width: $small-mobile) {
        @content;
    }
}

@mixin mobile {
    @media (min-width: $mobile) {
        @content;
    }
}

@mixin small-tab {
    @media (min-width: $small-tab) {
        @content;
    }
}

@mixin big-tab {
    @media (min-width: $big-tab) {
        @content;
    }
}

@mixin desktop {
    @media (min-width: $desktop) {
        @content;
    }
}

@mixin mid-desktop {
    @media (min-width: $mid-desktop) {
        @content;
    }
}

@mixin large-desktop {
    @media (min-width: $large-desktop) {
        @content;
    }
}

@mixin center() {
    display: flex;
    justify-content: center;
    align-items: center;
}

// keyframes 
@-webkit-keyframes sdb10 {
    0% {
      -webkit-transform: translate(0, 0);
      opacity: 0;
    }
    40% {
      opacity: 1;
    }
    80% {
      -webkit-transform: translate(0, 20px);
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  @keyframes sdb10 {
    0% {
      transform: translate(0, 0);
      opacity: 0;
    }
    40% {
      opacity: 1;
    }
    80% {
      transform: translate(0, 20px);
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body {
    font-family: 'UnileverShilling', serif;
}

main {
    overflow: hidden;
}

// Common
.btn-custom {
    padding: 0;
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 0;

    span {
        background-color: $white-color;

        &.text {
            font-size: 1.1rem;
            color: $primary-color  !important;
            padding: .8rem 1.4rem 1rem 1.4rem;
        }

        &.icon {
            @include center();
            margin-left: 3px;
            color: $primary-color  !important;
            padding: .8rem 1rem 1rem 1rem;
            align-self: stretch;

            svg {
                width: 20px;
                height: 20px;
            }
        }
    }
    &._blue {
        span {
            background-color: $primary-color;
            &.text {
                color: $white-color  !important;
            }
            &.icon {
                color: $white-color  !important;
            }
        }
    }
}

.headings {
    .h4 {
        font-size: 2.2rem;

        @include big-tab() {
            font-size: 3rem;
        }
    }

    margin-bottom: 40px;

    @include big-tab() {
        margin-bottom: 60px;
    }
}

.headings-2 {
    h4 {
        font-size: 30px;
        font-weight: 600;

        @include small-tab() {
            font-size: 40px;
        }
    }

    p {
        font-size: 18px;

        @include small-tab() {
            font-size: 20px;
        }
    }

    margin-bottom: 45px;
}

@keyframes inflate-deflate{
	to { transform: scale(1.2); }
}

@keyframes inflate-deflate-small{
	to { transform: scale(1.1); }
}

section {
    padding: 60px 0;

    @include small-tab() {
        padding: 100px 0;
    }
}

button {

    &:focus,
    &:active {
        outline: none !important;
        box-shadow: none !important;
    }
}


nav {
    &.navbar {
        background-color: $white-color;
        padding-top: 1.5rem;
        padding-bottom: 1.5rem;
        position: fixed;
        top: 0;
        left: 0;
        z-index: 100;
        width: 100%;
        // overflow: hidden;

        @include small-tab() {
            padding-top: 2.5rem;
            padding-bottom: 2.5rem;
        }

        .navbar-brand {
            padding: 0;
            position: absolute;
            top: 0;
            left: 0;
            img {
                width: 183px;
                @include small-tab() {
                    width: 253px;
                }
                @include big-tab() {
                    width: 263px;
                }
            }
        }

        .navbar-nav {
            .nav-item {
                padding-left: 1.3rem;
                padding-right: 1.3rem;
                @include desktop() {
                    padding-left: 2.3rem;
                    padding-right: 2.3rem;
                }
                .nav-link {
                    color: $ocean-blue;
                    font-size: 1.1rem;
                    font-weight: 500;
                    position: relative;
                    padding-left: 0;
                    padding-right: 0;
                    &::after {
                        content: '';
                        position: absolute;
                        bottom: -2px;
                        height: 2px;
                        left: auto;
                        right: 0;
                        transition: .4s;
                        width: 0;
                        background-color: $denim-blue;
                    }
                    &:not(.button) {
                        &:hover {
                            color: $denim-blue;
                            &::after {
                                left: 0;
                                right: auto;
                                width: 100%;
                            }
                        }
                        &.active {
                            color: $denim-blue;
                        }
                    }

                    &.button {
                        font-size: 1rem;
                        @include center();
                        // padding: 0 !important;
                        background-color: $ocean-blue;
                        color: $white-color;
                        padding: .5rem .7rem .5rem 1rem;
                        position: relative;

                        svg {
                            margin-left: 26px;
                            margin-top: 1px;
                            color: $primary-color  !important;
                            font-size: 1.3rem;

                            path {
                                fill: $white-color;
                            }
                        }

                        &::before {
                            position: absolute;
                            content: '';
                            top: 0;
                            right: 40px;
                            width: 3px;
                            background-color: $white-color;
                            height: 100%;
                        }
                    }
                }

                &.dropdown-wrap {
                    position: relative;
                    cursor: pointer;
                    &:hover {
                        .dropdown {
                            top: 50px;
                            opacity: 1;
                            pointer-events: all;
                        }    
                        .profile {
                            span {
                                color: $primary-color;
                            }
                            svg {
                                color: $primary-color;
                            }
                        }
                    }
                    .dropdown {
                        position: absolute;
                        width: 200px;
                        top: 50px;
                        right: 0;
                        // transform: translateX(-50%);
                        z-index: 15;
                        padding: 10px;
                        background-color: $white-color;
                        box-shadow: 0px 0px 20px 4px #001f8236;
                        transition: .4s;
                        pointer-events: none;
                        opacity: 0;
                        &::before {
                            content: '';
                            position: absolute;
                            width: 40px;
                            height: 20px;
                            background-color: $white-color;
                            right: 0;
                            top: -10px;
                            // transform: translateX(-50%);
                            pointer-events: none;
                            clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
                        }
                        &::after {
                            content: '';
                            position: absolute;
                            width: 100%;
                            height: 30px;
                            left: 0;
                            top: -25px;
                        }
                        ul {
                            li {
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                a, button {
                                    padding: 10px;
                                    text-decoration: none;
                                    background-color: transparent;
                                    border: 0;
                                    color: $primary-color;
                                    transition: .4s;
                                    &:hover {
                                        color: $ocean-blue;
                                    }
                                }
                            }
                        }
                    }
                    .profile {
                        display: flex;
                        align-items: center;
                        span {
                            margin-left: 8px;
                            color: $ocean-blue;
                            transition: .4s;
                        }
                        svg {
                            transition: .4s;
                            font-size: 34px;
                            color: $ocean-blue;
                        }
                    }
                }
                &.login_btn {
                    a {
                        display: flex;
                        padding: 0;
                        padding-top: 5px;
                        align-items: center;
                        &::after {
                            display: none;
                        }
                        svg {
                            font-size: 34px;
                        }
                        span {
                            margin-left: 8px;
                        }
                    }
                }
            }
        }

        .navbar-toggler {
            border: unset;

            .navbar-toggler-icon {
                background-image: unset;

                svg {
                    path {
                        fill: $primary-color;
                    }
                }
            }
        }
        &::before {
            content: '';
            width: 100%;
            height: 1px;
            background-color: $primary-color;
            position: absolute;
            left: 0;
            bottom: 0;
            z-index: 10;
        }
    }
}

section.banner {
    padding: 0;
    position: relative;
    margin-top: 86px;
    @include small-tab() {
        margin-top: 118px;
    }
    @include big-tab() {
        margin-top: 122px;
    }
    .banner-slider-wrap {
        .banner-item {
            position: relative;
            &::before {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background-color: rgba($color: #000, $alpha: 0.3);
            }
            .bg-img {
                img {
                    width: 100%;
                    object-fit: cover;
                    height: 600px;

                    @include small-tab() {
                        height: 820px;
                    }
                }
            }

            .banner-text {
                position: absolute;
                left: 0;
                top: 50%;
                transform: translateY(-50%);
                width: 100%;
                @include small-tab(){
                    top: 40%;
                }
                @include big-tab() {
                    top: 50%;
                }

                // height: 100%;
                span {
                    &.banner-heading {
                        color: $canary-yellow;
                        padding-left: 60px;
                        position: relative;
                        font-size: 1rem;

                        @include small-tab() {
                            font-size: 1.3rem;
                        }

                        &::before {
                            position: absolute;
                            content: '';
                            background-color: $canary-yellow;
                            bottom: 8px;
                            left: 0;
                            height: 2px;
                            width: 50px;
                            border-radius: 8px;
                        }
                    }
                }

                h2 {
                    color: $white-color;
                    font-size: 2rem;
                    margin-top: 1.4rem;

                    @include small-tab() {
                        font-size: 3.5rem;
                    }
                }

                p {
                    color: $white-color;
                    font-size: 1.1rem;
                    margin-top: 1rem;

                    @include small-tab() {
                        margin-top: 2.5rem;
                        font-size: 1.4rem;
                    }
                }

                // a {
                //     // margin-top: 2.5rem;
                // }

                button.watch-intro {
                    color: $white-color;

                    span {
                        font-size: 1.2rem;
                    }

                    svg {
                        font-size: 1.8rem;
                        margin-left: .8rem;
                    }
                }
            }
        }
    }
}

section.latest-stories {
    .headings {
        color: $primary-color;
    }

    .latest-stories-mobile {
        .splide__pagination__page {
            background-color: rgba($color: $primary-color, $alpha: 0.35);

            &.is-active {
                background-color: $primary-color;
            }

        }
    }
}

// Story Card
.story-card-wrapper {
    margin-bottom: 1.6rem;
    transition: .4s;
    padding: 5px;
    background-color: $primary-color;

    @include big-tab() {
        background-color: $white-color;
        box-shadow: 0px 0px 15px 10px rgba(0, 94, 255, 0.15);
        -webkit-box-shadow: 0px 0px 15px 10px rgba(0, 94, 255, 0.15);
        -moz-box-shadow: 0px 0px 15px 10px rgba(0, 94, 255, 0.15);
    }

    .story-card {
        position: relative;
        cursor: pointer;

        // a {
        //     // display: block;
        // }

        figure {
            img {
                min-height: 460px;
                object-fit: cover;
            }
        }

        .story-text {
            padding: 0px 20px;
            position: absolute;
            bottom: 25px;
            left: 0;
            width: 100%;
            color: $white-color;

            span.name {
                font-weight: 500;
                font-size: 1.2rem;
            }
        }

        &.story::before {
            position: absolute;
            content: '';
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: rgb(0, 0, 0);
            background: -moz-linear-gradient(0deg, rgba(0, 0, 0, 0.39959733893557425) 0%, rgba(146, 206, 255, 0) 100%);
            background: -webkit-linear-gradient(0deg, rgba(0, 0, 0, 0.39959733893557425) 0%, rgba(146, 206, 255, 0) 100%);
            background: linear-gradient(0deg, rgba(0, 0, 0, 0.39959733893557425) 0%, rgba(146, 206, 255, 0) 100%);
            filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#000000", endColorstr="#92ceff", GradientType=1);
        }

        &.cta {
            background-color: $sky-blue;

            .cta-text {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                width: 100%;
                text-decoration: none;
                .h2 {
                    display: block;
                    color: $primary-color;
                    font-size: 2rem;
                    padding: 0 40px;
                    text-align: center;
                    
                    @include big-tab() {
                        text-align: left;
                        padding: 0 10px;
                    }

                    @include mid-desktop() {
                        padding: 0 20px;
                        font-size: 2.6rem;
                    }
                }

                .view-all {
                    margin-top: 5rem;

                    span {
                        background-color: $white-color;
                        color: #7706bc !important;
                    }
                }
            }
        }
    }

    &:hover {
        background-color: $primary-color;
        transform: translateY(-10px);
    }

}

section.how-it-works {
    background-image: url('../../public/images/homepage/how-it-works-background-image.jpg');
    background-position: top;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    &::before {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        content: '';
        background-color: rgba($color: #000, $alpha: 0.5);
    }
    .headings {
        color: $white-color;

        .h4 {
            display: block;
            margin-bottom: 1.6rem;
        }

        p {
            font-size: 1.2rem;
        }
    }

    .steps-wrapper {
        .steps-card {
            figure {
                background-color: $white-color;
                padding: 6px;

                img {
                    height: 260px;
                    object-fit: cover;
                }
            }

            .step-number {
                // margin-top: 1.4rem;
                min-width: 40px;
                min-height: 40px;
                background-color: #ffc000;
                @include center();
                border-radius: 50%;
                color: #2036c7;
                font-size: 1.2rem;
                font-weight: 500;
                span {
                    margin-bottom: 4px;
                }

                @include small-tab() {
                    // margin-top: 2.2rem;
                    min-width: 50px;
                    min-height: 50px;
                }
            }

            p {
                // margin-top: 1rem;
                color: $white-color;
                font-size: 1.3rem;

                @include small-tab() {
                    // margin-top: 1.4rem;
                    font-size: 1.3rem;
                }
            }
        }
    }
}

@keyframes upDown {
    from {top: 0;}
    to {top: 60%;}
}
@keyframes downUp {
    from {bottom: -25px;}
    to {bottom: 30%;}
}
@keyframes scaleUpDown {
    from {transform: scale(1.1);}
    to {transform: scale(.75);}
}

section.share-your-story {
    position: relative;
    // background-color: #ff5450;
    background-color: #f6f5ed;

    .btn-custom {
        span {
            background-color: $white-color !important;
        }
    }

    .headings {
        z-index: 1;
        position: relative;
        color: $primary-color;

        .h4 {
            margin-bottom: 1.6rem;
        }

        p {
            font-size: 1.15rem;
        }
        margin-bottom: 60px;
    }

    .btn-custom {
        span {
            background-color: #76ddf5;
            color: #7706bc !important;
        }
    }

    img.top-left {
        position: absolute;
        top: 0;
        left: 0;
        width: 100px;
        z-index: 0;
        display: none;
        z-index: 0;
        @include big-tab() {
            display: block;
            width: 200px;
        }
        @include desktop() {
            width: 300px;
        }
    }

    img.top-right {
        position: absolute;
        top: 0;
        right: 0;
        width: 100px;
        z-index: 0;
        display: none;
        
        @include big-tab() {
            width: 200px;
            display: block;
        }
        @include desktop() {
            width: 300px;
        }
    }

    .slider-wrapper {
        margin-top: 3rem;

        .shared-story-item {
            position: relative;
            min-height: 180px;
            // background-image: url('../../public/images/homepage/Ellipse.png');
            
            @include mobile() {
                min-height: 250px;
            }

            .bg-image {
                img {
                    display: none;
                    
                    @include desktop() {
                        display: block;
                    }
                }
            }
            .story {
                img {
                    border-radius: 50%;
                    border: 15px solid #f3f3f3;
                    box-shadow: 2px 2px 25px -5px rgba(0,0,0,0.24);
                    -webkit-box-shadow: 2px 2px 25px -5px rgba(0,0,0,0.24);
                    -moz-box-shadow: 2px 2px 25px -5px rgba(0,0,0,0.24);
                }
                // transform-origin: center;
                // animation: inflate-deflate-small 2s infinite alternate;
            }

            .story-1 {
                animation: downUp 5s infinite ease-in alternate;
                position: absolute;
                bottom: -25px;
                left: 14%;
                z-index: 1;

                @include desktop() {
                    left: 14%;
                }

                img {
                    width: 80px;
                    height: 80px;
                    object-fit: cover;

                    @include mobile() {
                        width: 150px;
                        height: 150px;
                    }
                    @include big-tab() {
                        width: 150px;
                        height: 150px;
                    }

                }
            }

            .story-2 {
                animation: upDown 8s infinite ease-in alternate;
                position: absolute;
                top: 0;
                left: 50%;
                transform: translateX(-50%);
                z-index: 1;

                @include desktop() {
                    left: 45%;
                }

                img {
                    width: 70px;
                    height: 70px;
                    object-fit: cover;

                    @include mobile() {
                        width: 150px;
                        height: 150px;
                    }

                    @include small-tab() {}

                    @include big-tab() {
                        width: 120px;
                        height: 120px;
                    }

                }
            }

            .story-3 {
                animation: downUp 6s infinite ease-in alternate;
                position: absolute;
                bottom: -30px;
                right: 6%;
                z-index: 1;

                @include desktop() {
                    right: 14%;
                }

                img {
                    width: 60px;
                    height: 60px;
                    object-fit: cover;

                    @include mobile() {
                        width: 150px;
                        height: 150px;
                    }

                    @include small-tab() {}

                    @include big-tab() {
                        width: 150px;
                        height: 150px;
                    }

                }
            }

            span {
                display: block;
                z-index: 0;

                @include desktop() {
                    // display: none;
                }

                &.purple-1 {
                    position: absolute;
                    width: 60px;
                    height: 60px;
                    border-radius: 50%;
                    background-color: $royal-purple;
                    top: 45%;
                    left: 14%;
                    animation: scaleUpDown 4s infinite ease-in alternate;
                    @include mobile() {
                        left: 20%;
                    }
                    
                    @include big-tab() {
                        left: 4%;
                        width: 70px;
                        height: 70px;
                    }
                }

                &.purple-2 {
                    position: absolute;
                    width: 75px;
                    height: 75px;
                    border-radius: 50%;
                    background-color: $sky-blue;
                    top: 74%;
                    left: 95%;
                    animation: scaleUpDown 6s infinite ease-in alternate;
                    @include big-tab() {
                        width: 105px;
                        height: 105px;
                    }
                }

                &.purple-3 {
                    position: absolute;
                    width: 50px;
                    height: 50px;
                    border-radius: 50%;
                    background-color: $royal-purple;
                    top: 15%;
                    left: 93%;
                    animation: scaleUpDown 8s infinite ease-in alternate;
                    @include big-tab() {
                        width: 85px;
                        height: 85px;
                    }
                }

                &.purple-4 {
                    position: absolute;
                    width: 50px;
                    height: 50px;
                    border-radius: 50%;
                    background-color: $royal-purple;
                    top: 5%;
                    left: 33%;
                    animation: scaleUpDown 8s infinite ease-in alternate;
                    @include big-tab() {
                        width: 70px;
                        height: 70px;
                    }
                }

                &.blue-1 {
                    position: absolute;
                    width: 30px;
                    height: 30px;
                    border-radius: 50%;
                    background-color: $sky-blue;
                    top: 68%;
                    left: 30%;
                    animation: scaleUpDown 5s infinite ease-in alternate;
                    @include big-tab() {
                        width: 60px;
                        height: 60px;
                    }
                }
                &.blue-2 {
                    position: absolute;
                    width: 50px;
                    height: 50px;
                    border-radius: 50%;
                    background-color: $sky-blue;
                    top: 20%;
                    left: 65%;
                    animation: scaleUpDown 6s infinite ease-in alternate;
                    @include big-tab() {
                        width: 70px;
                        height: 70px;
                    }
                }
                &.blue-3 {
                    position: absolute;
                    width: 40px;
                    height: 40px;
                    border-radius: 50%;
                    background-color: $royal-purple;
                    top: 75%;
                    left: 57%;
                    animation: scaleUpDown 8s infinite ease-in alternate;
                    @include big-tab() {
                        width: 80px;
                        height: 80px;
                    }
                }

                // &.purple {
                //     position: absolute;
                //     width: 70px;
                //     height: 70px;
                //     border-radius: 50%;
                //     background-color: #7706bc;
                //     top: 10%;
                //     right: -3%;
                // }

                // &.navy {
                //     position: absolute;
                //     width: 45px;
                //     height: 45px;
                //     border-radius: 50%;
                //     background-color: #2036c7;
                //     top: 20%;
                //     right: 25%;
                // }
            }

        }
    }
}

section.achievements {
    .headings {
        position: relative;

        .h4 {
            // font-size: 2.6rem;
            color: $primary-color;
            font-size: 2.2rem;
            @include desktop() {
                font-size: 3rem;
            }
        }

        margin-bottom: 0;

        img {
            position: absolute;
            top: -20px;
            right: 0;
            width: 30px;
            animation: inflate-deflate 1s infinite alternate;

            @include mobile() {
                top: -45px;
                right: 70px;
            }

            @include small-tab() {
                width: 50px;
            }
        }
    }

    .achievement-card {
        position: relative;
        height: 200px;

        .text {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            text-align: center;
            width: 100%;
            padding: 0 30px;

            span {
                font-weight: 500;
                color: $white-color;
                font-size: 3rem;
            }

            p {
                margin-bottom: 0;
                font-weight: 500;
                color: $white-color;
                font-size: 1rem;
                @include big-tab() {
                    font-size: 1.2rem;
                }
                @include desktop() {
                    font-size: 1.4rem;
                }
            }
        }

        &.purple {
            background-color: #9c44c0;
        }

        &.light-red {
            background-color: #ff7c7d;
        }

        &.mint {
            background-color: #00d7c4;
        }

        &.sunset-red {
            background-color: #fe4044;
        }

        &.ballet-pink {
            background-color: #ffa0de;
        }

        &.sky-blue {
            background-color: #01cbfc;
        }
        &.primary-color {
            background-color: $primary-color;
            position: relative;
            &.dark {
                &::before {
                    position: absolute;
                    content: '';
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    background-color: rgba($color: #000, $alpha: 0.1);
                }
            }
        }
    }
}

footer {
    background-color: $primary-color;
    padding: 40px 0 60px;
    position: relative;
    z-index: 0;

    .footer-img {
        position: absolute;
        bottom: 0;
        right: 0;
        width: 420px;

        @include small-tab() {
            width: 600px;
        }
    }

    .top-footer {
        position: relative;
        z-index: 1;
        color: $white-color;
        padding-bottom: 40px;

        a {
            color: $white-color;
            text-decoration: none;
            margin-bottom: 1rem;
            display: block;
        }

        .hob {
            font-size: 1rem;

        }
        a {
            display: block;
            margin-bottom: 30px;
            img {
                width: 180px;
            }
        }
        .social-icons {
            display: flex;
            margin-top: 15px;
            @include big-tab() {
                justify-content: flex-end;
            }
            a {
                margin-right: 20px;
                width: 45px;
                background-color: #ffffff17;
                height: 45px;
                @include center();
                padding: 5px;
                svg {
                    font-size: 20px;
                }
            }
        }
    }

    .bottom-footer {
        position: relative;
        z-index: 1;
        color: $white-color;
        padding-top: 40px;
        border-top: 2px solid $white-color;

        .nav-item {
            a {
                color: $white-color;
                text-decoration: none;
                display: block;
                position: relative;
                transition: .4s;
                &::after {
                    content: '';
                    position: absolute;
                    bottom: -2px;
                    height: 2px;
                    left: auto;
                    right: 0;
                    transition: .4s;
                    width: 0;
                    background-color: #76ddf5;
                }
                &:hover {
                    color: #76ddf5;
                    &::after {
                        left: 0;
                        right: auto;
                        width: 100%;
                    }
                }
            }
        }
    }

    .footer-strip {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;

        span {
            height: 8px;
            flex: 1;

            &.pink {
                background-color: #fca6de;
            }

            &.yellow {
                background-color: #ffe300;
            }

            &.red {
                background-color: #ff5450;
            }

            &.mint {
                background-color: #00d7c4;
            }
        }
    }
}

.side-menu-wrapper {
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: $primary-color;
    top: 0;
    left: -100%;
    transition: .8s;
    z-index: 1024;

    &.open {
        left: 0;
    }

    .close-btn {
        svg {
            path {
                fill: $primary-color;
            }
        }
    }

    .navbar-logo {
        background-color: $white-color;
    }

    ul {
        // padding: 30px 0px 0px 0px;
        padding-left: 0;
        padding-top: 30px;

        li {
            width: 100%;
            height: 55px;
            font-size: 20px;
            border-left: 3px solid rgba($color: $white-color, $alpha: 0.75);
            display: flex;
            align-items: center;
            // border-top: 1px solid rgba($color: $white-color, $alpha: 0.75);
            border-right: 1px solid rgba($color: $white-color, $alpha: 0.75);

            .dot-container {
                width: 80px;
                height: 80px;
                border-right: 1px solid rgba($color: $white-color, $alpha: 0.75);
                display: flex;
                align-items: center;
                justify-content: center;

                .dot {
                    width: 10px;
                    height: 10px;
                    border-radius: 100%;
                    background-color: rgba($color: $white-color, $alpha: 1);
                }
            }





            &.active {

                border-left: 3px solid rgba($color: $white-color, $alpha: 0.75);
                // background-color: $white-color;

                // a {
                //     color: $white-color;
                // }

                .dot {
                    background-color: $white-color;
                }
            }

            // &:last-child {
            //     border-bottom: 1px solid rgba($color: $white-color, $alpha: 0.75);
            // }

            a, button {
                font-size: 18px;
                margin-left: 10px;
                display: inline-block;
                color: rgba($color: $white-color, $alpha: 1);


                .dot {
                    width: 12px;
                    height: 12px;
                    border-radius: 100%;
                    background-color: $white-color;
                    margin-right: 10px;
                    display: block;
                    
                }
                &.active {
                    color: $ocean-blue;
                    .dot {
                        background-color: $ocean-blue !important;
                    }
                }
            }

        }

    }
}

// You Page
section.you-banner {
    background-image: url('../../public/images/you/map-bg.png');
    background-position: top;
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 600px;
    position: relative;

    margin-top: 86px;
    @include small-tab() {
        margin-top: 118px;
    }
    @include big-tab() {
        margin-top: 122px;
        min-height: 750px;
    }

    .banner-text {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 100%;

        .hul-logo {
            flex: 30%;
            img {
                width: 150px;

                @include desktop() {
                    width: 100%;
                }

                @include big-tab() {
                    // width: 250px;
                }
            }
        }

        .hob-logo {
            flex: 30%;
            margin-top: 30px;
            @include big-tab() {
                margin-top: 0px;
            }
            img {
                width: 220px;

                @include big-tab() {
                    width: 200px;
                }

                @include desktop() {
                    width: 100%;
                }

                // @include big-tab() {
                //     width: 250px;
                // }
            }
        }

        .headings {
            flex: 60%;
            margin-bottom: 0;
            margin: 0 0px;
            @include big-tab() {
                margin: 0 80px;
            }
            @include desktop() {
                margin: 0 150px;
            }

            .h3 {
                color: $white-color;
                font-size: 2rem;
                margin-bottom: 1rem;
                display: block;

                @include big-tab() {
                    margin-bottom: 2.5rem;
                    font-size: 2.6rem;
                }
                @include desktop() {
                    margin-bottom: 2.5rem;
                    font-size: 3.3rem;
                }
            }

            p {
                color: $white-color;
                margin-bottom: 1rem;
                font-size: 1rem;

                @include big-tab() {
                    margin-bottom: 3.4rem;
                    font-size: 1.4rem;
                }
            }
        }
        .mobile-images {
            figure.hul {
                img {
                    width: 80px;
                    @include mobile() {
                        width: 120px;
                    }
                }
            }
            figure.hob {
                img {
                    width: 110px;
                    @include mobile() {
                        width: 160px;
                    }
                }
            }
        }
    }

    .map-heads {
        position: absolute;
        opacity: 0;

        img {
            width: 40px;
            height: 40px;
            object-fit: cover;
            @include big-tab() {
                width: 65px;
                height: 65px;
            }
        }

        &.one {
            top: 55%;
            left: 10%;
            display: none;
            @include big-tab() {
                display: block;
            }
        }

        &.two {
            top: 18%;
            left: 26%;
        }

        &.three {
            top: 14%;
            left: 44%;
        }

        &.four {
            top: 27%;
            right: 32%;
            display: none;
            @include big-tab() {
                display: block;
            }
        }

        &.five {
            top: 34%;
            right: 23%;
            display: none;
            @include big-tab() {
                display: block;
            }
        }

        &.six {
            top: 14%;
            right: 10%;
        }

        &.seven {
            top: 15%;
            left: 5%;
        }

        &.eight {
            top: 50%;
            left: 5%;
            display: none;
            @include big-tab() {
                display: block;
            }
        }

        &.nine {
            top: 80%;
            right: 10%;
        }

        &.ten {
            top: 55%;
            right: 65%;
        }
    }
}

//Podcast
section.podcast {
    background-image: url('../../public/images/theme/podcast-background.jpg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    padding: 50px 0px;

    @include big-tab() {
        padding: 220px 0px 400px;
    }

    .left {
        .podcast-title {
            font-size: 35px;

            @include mobile() {
                font-size: 50px;
            }

            color: $primary-color;
            max-width: 400px;
        }
        .podcast-heading {
            font-size: 1.2rem;
            color: $denim-blue;
        }

        .padcast-descp {
            margin-top: 50px;
            font-size: 18px;
            color: gray;
            max-width: 480px;
        }

        .btn-custom {
            margin-top: 40px;

            span {
                background-color: #7bdbf1;

                &.text {
                    color: #6409a4 !important;
                }

                &.icon {
                    svg {
                        path {
                            fill: #6409a4 !important;
                        }

                    }
                }
            }
        }
    }

    .right {
        margin: 300px 0px 827px;
        @include mobile(){
            margin:300px 0px 1000px;
        }
        @include big-tab() {
            margin: 0;
        }

        position: relative;

        span {
            transition: .4s;
            display: none;
            @include big-tab() {
                display: block;
            }
            &.sky-blue {
                border: 1px solid red;
                position: absolute;
                top: -190px;
                left: 95px;
                width: 150px;
                height: 150px;
                border-radius: 50%;
                background-color: $sky-blue;
                border: 18px solid #f3f3f3;
                box-shadow: 2px 2px 15px -5px rgba(0,0,0,0.24);
                -webkit-box-shadow: 2px 2px 15px -5px rgba(0,0,0,0.24);
                -moz-box-shadow: 2px 2px 15px -5px rgba(0,0,0,0.24);
                @include desktop() {
                    left: 150px;
                }
            }
            &.sunset-red {
                position: absolute;
                top: 290px;
                right: 80px;
                width: 120px;
                height: 120px;
                border-radius: 50%;
                background-color: $sunset-red;
                border: 18px solid #f3f3f3;
                box-shadow: 2px 2px 15px -5px rgba(0,0,0,0.24);
                -webkit-box-shadow: 2px 2px 15px -5px rgba(0,0,0,0.24);
                -moz-box-shadow: 2px 2px 15px -5px rgba(0,0,0,0.24);
                @include desktop() {
                    top: 350px;
                    right: 170px;
                }
            }
            &:hover {
                transform: scale(1.1);
            }
        }

        .img-6 {
            position: absolute;
            top: 450px;
            left: 4%;
            width: 80%;

            img {
                width: 100%;
            }
            @include mobile(){
                top: 500px;
            }
            @include big-tab(){
                left: -50%;
            }

            @include big-tab() {
            }
        }

        .podcast-img {
            position: absolute;
            z-index: 2;
            cursor: pointer;
            &::after {
                content: '';
                position: absolute;
                width: 100%;
                background-color: #fcfcfa;
                border-radius: 50%;
                z-index: 0;
                height: 100%;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                transition: 0.4s;
            }

            .member-info {
                opacity: 0;
                transition: 0.4s;
                position: absolute;
                background-color: $white-color;
                z-index: 20;
                padding: 10px 15px;
                border-top-left-radius: 20px;
                border-top-right-radius: 20px;
                border-bottom-left-radius: 20px;
                border-bottom-right-radius: 20px;
                width: 200px;
                pointer-events: none;
                @include small-tab() {
                    border-bottom-right-radius: 0;
                }

                .name {
                    font-size: 14px;
                    margin-bottom: 1px;
                    color: $primary-color;
                }

                .desig {
                    margin: 0;
                    font-size: 14px;
                    color: rgb(146, 146, 146)
                }
            }

            img {
                width: 100%;
                position: relative;
                z-index: 1;
                transform: scale(1);
                transition: 0.1s;
                border-radius: 50%;
            }

            &:hover {
                &::after {
                    width: 125%;
                    height: 125%;
                    border-radius: 50%;
                }

                .member-info {
                    opacity: 1;
                    pointer-events: all;
                }

                img {
                    transform: scale(1.1);
                    width: 100%;
                    position: relative;
                    z-index: 1;
                    box-shadow: -32px 14px 53px -32px rgba(140, 140, 140, 0.75);
                    -webkit-box-shadow: -32px 14px 53px -32px rgba(140, 140, 140, 0.75);
                    -moz-box-shadow: -32px 14px 53px -32px rgba(140, 140, 140, 0.75);
                    border-radius: 50%;
                }
            }

            &.img-1 {
                position: absolute;
                top: -243px;
                right: 20px;
                width: 40%; 
                z-index: 10;
                @include big-tab(){
                    position: absolute;
                    top: -35px;
                    right: 20px;
                    width: 35%;
                }
                @include desktop() {
                    top: -180px;
                }

                .member-info {
                    top: -70px;
                    right: 50%;
                    transform: translateX(50%);
                    @include small-tab() {
                        transform: translateX(0);
                        top: -10px;
                        right: 240px;
                    }
                    @include big-tab() {
                        top: 20px;
                        right: 140px;
                        z-index: 10;
                    }
                    @include desktop() {
                        right: 205px;
                    }
                    @include large-desktop() {
                        right: 210px;
                    }
                }
            }

            &.img-2 {
                position: absolute;
                top: -137px;
                left: -10%;
                width: 50%;
                @include mobile(){
                    left: 9%;
                    width: 35%;
                }
                @include big-tab(){
                    top: -30px;
                    left: -8%;
                    width: 35%;
                }

                .member-info {
                    top: -80px;
                    right: 50%;
                    transform: translateX(50%);
                    @include small-tab() {
                        top: -60px;
                        right: 100px;
                        transform: translateX(0);
                    }
                    @include big-tab(){
                        top: 20px;
                        right: 140px;
                    }
                    @include desktop() {
                        right: 205px;
                    }
                    @include large-desktop() {
                        right: 210px;
                    }
                }
            }

            &.img-3 {
                position: absolute;
                top: 30px;
                right: 10px;
                width: 40%;
                @include mobile(){
                    top: 120px;
                    width: 30%;
                }
                @include small-tab() {
                    right: 170px;
                }
                @include big-tab() {
                    width: 30%;
                    top: 100px;
                    right: 165px;
                }
                @include desktop() {
                    top: 55px;
                    right: 200px;
                }

                .member-info {
                    top: -90px;
                    right: 50%;
                    transform: translateX(50%);
                    @include small-tab() {
                        transform: translateX(0);
                        top: 20px;
                        right: 190px;
                    }
                    @include big-tab() {
                        right: 120px;
                    }
                    @include desktop() {
                        right: 180px;
                    }
                }
            }

            &.img-4 {
              
                position: absolute;
                top: 139px;
                left: 0;
                width: 40%;
                @include mobile(){
                    top: 249px;
                    width: 30%;
                }

                @include big-tab() {
                    top: 240px;
                    left: 35px;
                    width: 30%;
                }

                .member-info {
                    top: -70px;
                    right: 50%;
                    transform: translateX(50%);
                    @include small-tab() {
                        transform: translateX(0);
                        top: -70px;
                        right: 50px;
                    }
                    @include big-tab() {
                        top: 20px;
                        right: 120px;
                    }
                    @include desktop() {
                        right: 180px;
                    }
                }
            }

            &.img-5 {
               
                position: absolute;
                top: 260px;
                right: 0;
                width: 40%;
                @include  mobile(){
                    top: 367px;
                    right: 0;
                    width: 25%;
                }
                @include small-tab() {
                    top: 270px;
                }
                @include big-tab() {
                    width: 30%;
                    top: 150px;
                }

                .member-info {
                    top: -90px;
                    left: -100px;
                    @include small-tab() {
                        top: -35px;
                        left: -180px;
                    }
                    @include big-tab(){
                        left: -190px;
                        top: 0;
                    }
                    @include desktop() {
                        left: -150%;
                        top: 0;
                    }
                    @include mid-desktop() {
                        left: -140%;
                    }
                    @include large-desktop() {
                        left: -130%;
                    }
                }
                &:hover {
                    img {
                        transform: scale(1.2);
                    }
                }
            }
        }
    }
}

section.tell-your-story {
    .headings {
        .h4 {
            color: $primary-color;
        }

        p {
            color: $primary-color;
        }
    }

    .you-form-wrapper {
        background-color: #9d44c0;
        // padding: 40px;
        border-radius: 8px;
        // overflow: hidden;
        .form-steps {
            padding: 20px 20px 0;

            @include big-tab() {
                padding: 40px;
            }

            .steps-wrapper {
                flex: 25%;

                @include big-tab() {
                    margin-bottom: 65px;
                }

                .step-icon {
                    width: 56px;
                    height: 56px;
                    border-radius: 50%;
                    background-color: #b14dd8;
                    border: 4px solid #b14dd8;
                    @include center();
                    position: relative;
                    transition: .4s;

                    svg {
                        transition: .4s;
                        width: 25px;

                        path {
                            fill: $white-color;
                        }
                    }

                    &.after::after {
                        transition: .4s;
                        content: '';
                        position: absolute;
                        top: 50%;
                        transform: translateY(-50%);
                        left: -12px;
                        width: 10px;
                        height: 4px;
                        background-color: #b14dd8;
                        display: none;

                        @include small-mobile() {
                            display: block;
                            left: -34px;
                            width: 22px;
                        }

                        @include mobile() {
                            left: -60px;
                            width: 45px;
                        }

                        @include small-tab() {
                            left: -75px;
                        }

                        @include big-tab() {
                            top: -62px;
                            left: 50%;
                            transform: translateX(-50%);
                            width: 4px;
                            height: 45px;
                        }
                    }

                    &.active {
                        border: 4px solid #77ddf5;

                        &.after::after {
                            background-color: #77ddf5;
                        }
                    }

                    &.complete {
                        background-color: #77ddf5;
                        border-color: #77ddf5;

                        svg {
                            path {
                                fill: #773aca;
                            }
                        }

                        &.after::after {
                            background-color: #77ddf5;
                        }
                    }
                }

                .step-info {
                    display: none;

                    // &.active {
                    //     display: block;
                    // }
                    span {
                        font-size: .85rem;
                        color: rgba($color: $white-color, $alpha: 0.65);
                    }

                    p {
                        color: $white-color;
                        font-size: 1.15rem;
                    }

                    @include big-tab() {
                        display: block;
                    }
                }
            }

        }

        .form-content {
            min-height: unset;
            padding: 20px;
            border-left: unset;
            position: relative;
            z-index: 1;
            
            @include big-tab() {
                border-left: 1px solid #ca63f2 !important;
                padding: 40px;
                min-height: 766px;
            }
            &.auth {
                min-height: unset;
            }
            span.step {
                color: rgba($color: $white-color, $alpha: 0.65);
                font-size: .95rem;
            }

            p {
                color: $white-color;
                font-size: 1.2rem;
            }

            .form-group {
                margin-bottom: 1rem;

                label {
                    color: rgba($color: $white-color, $alpha: 0.65);
                    font-size: .9rem;
                    margin-bottom: 10px;
                }

                input:-internal-autofill-selected  {
                    background-color: #ab52ce !important;
                }
                .form-control {
                    border-radius: 0;
                    border-color: #ca63f2;
                    background-color: #ab52ce;
                    padding: .8rem .75rem;
                    color: $white-color;

                    &::placeholder {
                        color: rgba($color: $white-color, $alpha: 0.5) !important;
                    }

                    &:focus,
                    &:active {
                        box-shadow: none;
                    }
                    &::placeholder {
                        color: #ffffff2b;
                    }
                }

                .react-select-container {
                    border-radius: 0;
                    border-color: #ca63f2;
                    background-color: #ab52ce;
                    box-shadow: none;

                    .react-select__control {
                        border-radius: 0;
                        border-color: #ca63f2;
                        background-color: #ab52ce;
                        box-shadow: none;

                        .react-select__value-container {
                            padding: 10px 8px !important;

                            .react-select__placeholder {
                                color: $white-color;
                            }

                            .react-select__single-value {
                                color: $white-color;
                            }

                            .react-select__input-container {
                                color: $white-color;
                            }
                        }
                    }

                    .react-select__menu {
                        border-radius: 0;
                        background-color: #ab52ce;

                        .react-select__option {
                            color: $white-color;
                        }

                        .react-select__option--is-focused {
                            background-color: #ca63f2;
                        }

                        .react-select__option--is-selected {
                            background-color: #ca63f2;
                        }
                    }
                }

                // .css-1s2u09g-control, .css-1pahdxg-control {
                //     border-radius: 0;
                //     border-color: #ca63f2;
                //     background-color: #ab52ce;
                //     box-shadow: none;
                //     .css-319lph-ValueContainer {
                //         padding: 10px 8px !important;
                //         .css-14el2xx-placeholder, .css-qc6sy-singleValue {
                //             color: $white-color;
                //         }
                //     }
                // }
                // .css-26l3qy-menu {
                //     background-color: #ca63f2;
                //     .css-4ljt47-MenuList {
                //       color: $white-color !important;  
                //     }
                // }
                &.next-btn-wrapper {
                    button.btn-custom {
                        span {
                            background-color: #77ddf5;
                            color: #9d44c0 !important;

                            &.text {
                                padding: .3rem 1rem .5rem 1rem;

                                @include small-tab() {
                                    padding: .5rem 1.4rem .7rem 1.4rem;
                                }
                            }

                            &.icon {
                                padding: .3rem .6rem .3rem .6rem;

                                @include small-tab() {
                                    padding: .5rem 1rem .5rem 1rem;
                                }
                            }
                        }

                        &.prev {
                            span {
                                &.text {
                                    margin-left: 3px;
                                }

                                &.icon {
                                    svg {
                                        transform: scaleX(-1);
                                    }
                                }
                            }
                        }
                    }
                }

                .record-audio-box {
                    padding: 20px;
                    border: 1px solid #ca63f2;
                    background-color: #ab52ce;
                    min-height: 220px;
                    @include center();

                    button {
                        &.btn-record {
                            width: 60px;
                            height: 60px;
                            border: 3px solid #ca63f2;
                            background-color: #9d44c0;
                            @include center();
                            border-radius: 50%;

                            svg {
                                font-size: 1.8rem;

                                path {
                                    fill: rgba($color: $white-color, $alpha: 0.65);
                                }
                            }
                        }

                        &.btn-pause {
                            border: 3px solid #ca63f2;
                            background-color: #9d44c0;
                            @include center();
                            border-radius: 50px;
                            height: 60px;
                            width: 150px;

                            span {
                                color: rgba($color: $white-color, $alpha: 0.65);
                            }

                            svg {
                                font-size: 1.4rem;

                                path {
                                    fill: rgba($color: $white-color, $alpha: 0.65);
                                }
                            }
                        }

                        &.btn-save {
                            width: 60px;
                            height: 60px;
                            border: 3px solid #ca63f2;
                            background-color: #9d44c0;
                            @include center();
                            border-radius: 50%;

                            svg {
                                font-size: 1.6rem;

                                path {
                                    fill: rgba($color: $white-color, $alpha: 0.65);
                                }
                            }
                        }
                    }
                }

                .recorded-audio-list {

                    // max-height: 122px;
                    // overflow-y: scroll;
                    .audio-wrapper {
                        audio::-webkit-media-controls-panel {
                            background-color: #ab52ce;
                            border: 5px solid #ab52ce;
                            outline: #ab52ce solid 2px;
                            color: rgba($color: $white-color, $alpha: 0.65);
                        }

                        audio::-webkit-media-controls-play-button {
                            color: rgba($color: $white-color, $alpha: 0.65);
                        }

                        audio::-webkit-media-controls-current-time-display,
                        audio::-webkit-media-controls-time-remaining-display,
                        audio::-webkit-media-controls-timeline {
                            color: rgba($color: $white-color, $alpha: 0.65);
                        }

                        span {
                            color: rgba($color: $white-color, $alpha: 0.65);
                        }

                        svg {
                            font-size: 1.4rem;

                            path {
                                fill: rgba($color: $white-color, $alpha: 0.65);
                            }
                        }
                    }
                }

                .form-check-input {
                    margin-top: 0;
                    border-radius: 0;
                    background-color: #ab52ce;
                    border-color: #ca63f2;
                    min-width: 1.6rem;
                    height: 1.6rem;

                    &:focus,
                    &:active {
                        box-shadow: none;
                    }
                }

                .form-check-label {
                    margin-bottom: 0;
                    margin-left: 10px;
                    font-size: 1rem;
                    cursor: pointer;
                    a {
                        color: #76ddf5;
                        transition: .4s;
                        &:hover {
                            color: $primary-color;
                        }
                    }
                }

                .password-error-box {
                    border: 1px solid #CA63F2;
                    background-color: #AB52CE;
                    padding: 10px;
                    ul {
                        li {
                            color: rgba($color: $white-color, $alpha: 0.5);
                            svg {
                                margin-right: 8px;
                            }
                            display: flex;
                            align-items: flex-start;
                            &.success {
                                color: #1ED29B;
                            }
                            &.failed {
                                color: #ff7e7b;
                            }
                        }
                    }
                }
            }

            .form-error {
                color: #76ddf5;
                font-size: .9rem;
                a {
                    color: #76ddf5;
                    text-decoration: none;
                }
            }

            .share-box {
                padding: 50px 30px;
                border: 1px dashed $white-color;
                background-color: #ab52ce;

                svg {
                    font-size: 4rem;

                    path {
                        fill: $white-color;
                    }
                }

                p {
                    &.thank-you {
                        margin-top: 20px;
                        font-size: 1.4rem;
                        font-weight: 500;
                    }

                    &.message {
                        font-size: 1rem;
                        color: rgba($color: $white-color, $alpha: 0.65);
                        border-bottom: 1px dashed $white-color;
                        padding-bottom: 25px;
                    }

                    &.share {
                        font-size: 1.4rem;
                        font-weight: 500;
                    }
                }

                .share-btn-wrapper {
                    background-color: $white-color;
                    width: fit-content;
                    margin: 0 auto;
                    border-radius: 50px;

                    .social-btn {
                        width: 50px;
                        height: 50px;
                        @include center();
                        border-radius: 50%;

                        &:not(:last-of-type) {
                            margin-right: 10px;
                        }

                        svg {
                            font-size: 1.8rem;
                        }

                        &.whatsapp {
                            background-color: #25d366;
                        }

                        &.email {
                            background-color: #888;
                        }
                    }
                }
            }

            .thank-you-btn {
                span {
                    background-color: #77ddf5;
                    color: #9d44c0 !important;
                }
            }
        }
        .story-type-switch {
            .form-group {
                flex: 1;
                &:nth-child(2) {
                    margin-left: 10px;
                }
                input[type="radio"] {
                    display: none;
                    &:checked+label {
                        background-color: #ca63f2;
                        span.radio {
                            &::before {
                                position: absolute;
                                content: '';
                                width: 10px;
                                height: 10px;
                                background-color: #FFF;
                                top: 50%;
                                left: 50%;
                                transform: translate(-50%, -50%);
                                border-radius: 50%;
                            }
                        }
                    }
                }
                label {
                    border: 1px solid #ca63f2;
                    margin: 0;
                    height: 100%;
                    width: 100%;
                    @include center();
                    padding: 20px;
                    cursor: pointer;
                    span.radio {
                        width: 16px;
                        height: 16px;
                        border-radius: 50%;
                        border: 1px solid #FFF;
                        margin-right: 10px;
                        margin-top: 2px;
                        position: relative;
                    }
                }
            }
        }
    }
}

.thumbs {
    figure {
        position: relative;

        button {
            position: absolute;
            top: 5px;
            right: 5px;
            width: 30px;
            height: 30px;
            border-radius: 50%;
            @include center();
        }
    }
}

section.stories-banner {
    .banner-item {
        .bg-img {
            position: relative;
            img {
                position: relative;
                z-index: -1;
            }
            &::before {
                content: '';
                position: absolute;
                z-index: 0;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background-color: rgba(0, 0, 0, 0.3);
            }
        }
    }
    .stories-banner-slider {
        .splide__pagination {
            max-width: 100%;

            @include mobile() {
                max-width: 100%;
            }

            @include small-tab() {
                max-width: 720px;
            }

            @include big-tab() {
                max-width: 960px;
            }

            @include desktop() {
                max-width: 1140px;
            }

            @include mid-desktop() {
                max-width: 1320px;
            }

            margin: auto;
            bottom: 0;
            @include mobile() {
                top: 70%;
                bottom: unset;
                transform: translateY(-70%);
                flex-direction: column;
                align-items: flex-end;

            }
            @include small-tab(){
                top: 50%;
                transform: translateY(-50%);
            }

            .splide__pagination__page {
                width: 12px;
                height: 12px;
                margin: 8px;
                transform: unset;
                background-color: #8296d3;

                &.is-active {
                    transform: unset;
                    background-color: $white-color;
                }
            }
        }
    }
}

//Themes
section.themes {
    .headings-2 {
        color: $primary-color;
    }

    .theme-cards {
        .theme-card {
            overflow: hidden;

            &:hover {
                .theme-img {
                    img {
                        transform: scale(1.1);
                    }
                }
            }

            .theme-img {
                width: 100%;
                height: 200px;
                position: relative;
                margin-bottom: 0;

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    transform: scale(1);
                    transition: 0.4s;
                }

                position: relative;

                &::after {
                    content: '';
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    background-color: #0d112b70;
                }

                .img-text {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    font-size: 20px;
                    font-weight: 500;
                    transform: translate(-50%, -50%);
                    color: $white-color;
                    z-index: 1;
                    min-width: unset;
                    text-align: center;

                    @include mobile() {
                        min-width: 272px;
                    }
                }
            }
        }
    }
}

//Best Stories
section.best-stories {
    padding: 50px 0 160px;
    background-color: $salmon-red;
    position: relative;
    overflow: hidden;
    figure {
        &.top-left {
            position: absolute;
            top: 0;
            left: 0;
            z-index: 0;
            img {
                width: 800px;
            }
        }
    }
    .container {
        position: relative;
        z-index: 1;
    }

    .headings-2 {
        color: $white-color;
        text-align: center;

        p {
            line-height: 1.9;
        }
    }

    .stories-slider-wrap {
        margin-top: 90px;

        .story-image {
            width: 100%;
            height: 100%;
            margin-bottom: 0;
            position: relative;
            transition: .4s;
            @include big-tab() {
                margin: 12px;
            }
            &::after {
                content: '';
                position: absolute;
                width: 100%;
                height: 100%;
                bottom: -12px;
                right: -12px;
                background-color: $white-color;
                z-index: 0;
            }
            .img-wrapper {
                position: relative;
                z-index: 1;
                height: 400px;
                @include small-tab() {
                    height: 450px;
                }
                @include big-tab() {
                    height: 500px;
                }
                &::before {
                    content: '';
                    position: absolute;
                    top: 0;
                    left: 0;
                    background-color: rgba($color: #2036c7, $alpha: 0.2);
                    width: 100%;
                    height: 100%;
                }
                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }
        }

        .story-content {
            color: $white-color;

            .memeber-info {
                .memmber-name {
                    font-size: 30px;
                    font-weight: 500;
                    color: $white-color;
                    
                    @include mobile() {
                        font-size: 40px;
                    }
                }
                
                .memeber-details {
                    color: $white-color;
                    margin-top: 20px;

                    .desig {
                        margin-left: 158px;
                        font-size: 18px;

                        @include mobile() {
                            font-size: 28px;
                        }

                        font-weight: 400;
                        position: relative;

                        &::before {
                            content: '';
                            position: absolute;
                            left: 0;
                            top: 50%;
                            left: -65px;
                            width: 52px;
                            height: 2px;
                            background-color: $primary-color;
                        }
                    }

                    .excerpt {
                        margin-top: 60px;
                        max-width: 100%;
                        font-size: 18px;

                        @include mobile() {
                            max-width: 500px;
                        }
                    }

                    .btn-custom {
                        margin-top: 40px;
                    }
                }
            }
        }

        .splide__pagination {
            bottom: -100px;

            .splide__pagination__page {
                opacity: 1;
                width: 12px;
                height: 12px;
                margin: 8px;
                background-color: $white-color;
                
                &.is-active {
                    transform: unset;
                    background-color: $primary-color;
                }
            }
        }
    }
    .headings {
        p {
            font-size: 1.4rem;
        }
    }
}


section.theme-slider {
    background-color: #f7f7ef;
    margin-top: 86px;
    @include small-tab() {
        margin-top: 118px;
    }
    @include big-tab() {
        margin-top: 122px;
    }
    .theme-card {
        min-height: 120px;
        @include center();
        position: relative;
        border: 3px solid rgba($color: #000000, $alpha: 0.5);
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        text-decoration: none;
        transition: .4s;
        &::before {
            transition: .4s;
            position: absolute;
            content: '';
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: rgba($color: #000, $alpha: 0.5);
        }
        .bg-img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: -1;
            object-fit: cover;
        }
        span.h3 {
            position: relative;
            z-index: 1;
            color: $white-color;
            font-size: 1rem;
            font-weight: 500;
            text-align: center;
            padding: 0 30px;
            margin-bottom: 0;
            @include desktop() {
                font-size: 1.1rem;
            }
            @include large-desktop() {
                font-size: 1.4rem;
            }
        }
        &.active {
            // border: 3px solid #00a889;
            border: 3px solid #005eff;
            &::before {
                // background-color: rgba($color: #00a889, $alpha: 0.5);
                background-color: rgba(0, 94, 255, 0.5);
            }
        }
        &:hover {
            // border: 3px solid #00a889;
            border: 3px solid #005eff;
            &::before {
                // background-color: rgba($color: #00a889, $alpha: 0.5);
                background-color: rgba(0, 94, 255, 0.5);
            }
        }
    }
}


section.feeds {
    background-color: #f7f7ef;
    .headings {
        color: #005eff;
        span.h4 {
            display: block;
            text-align: center;
        }
    }
    .search-stories-wrapper {
        width: 100%;
        @include big-tab() {
            width: unset;
        }
        input.search-stories {
            border-radius: 0;
            padding: 12px 10px;
            border-width: 2px;
            width: 100%;
            &:focus {
                outline: none;
                box-shadow: none;
                border-color: $primary-color;
            }
            @include big-tab() {
                width: 400px;
            }
        }
        position: relative;
        ul {
            position: absolute;
            width: 100%;
            top: 100%;
            left: 0;
            max-height: 120px;
            overflow-y: scroll;
            box-shadow: 10px 10px 5px -5px rgba(214,214,214,0.25);
            -webkit-box-shadow: 10px 10px 5px -5px rgba(214,214,214,0.25);
            -moz-box-shadow: 10px 10px 5px -5px rgba(214,214,214,0.25);
            li {
                a {
                    display: block;
                    padding: 10px 10px;
                    background-color: #FFF;
                    text-decoration: none;
                    color: $primary-color;
                }
            }
        }
    }
}

.feed-card-wrapper {
    background-color: $white-color;
    border-radius: 20px;
    transition: .4s;
    &:hover {
        transform: translateY(-10px);
        box-shadow: 0px 5px 17px -6px rgba(0,94,255,0.25);
        -webkit-box-shadow: 0px 5px 17px -6px rgba(0,94,255,0.25);
        -moz-box-shadow: 0px 5px 17px -6px rgba(0,94,255,0.25);
        .feed-card {
            .feed-body {
                figure {
                    .unilever-logo {
                        opacity: 1;
                    }
                    &::before {
                        opacity: 1;
                    }
                }
            }
        }
    }
    .feed-card {
        border-radius: 17px;
        background-color: #f6f8fa;

        .feed-card-header {
            padding: 10px 20px;
            .profile {
                figure {
                    img {
                        width: 50px;
                        height: 50px;
                        border-radius: 50%;
                        object-fit: cover;
                    }
                }
                .text {
                    a {
                        color: #005eff;
                        text-decoration: none;
                        font-size: .9rem;
                    }
                    p {
                        font-size: .8rem;
                    }
                }
            }
            .views {
                font-size: .8rem;
            }
        }

        .feed-body {
            figure {
                border-radius: 12px;
                overflow: hidden;
                position: relative;
                img {
                    &.feed-image {
                        width: 100%;
                        height: 400px;
                        object-fit: cover;
                        @include big-tab() {
                            height: 450px;
                        }
                    }
                    &.unilever-logo {
                        width: 150px;
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                        opacity: 0;
                        transition: .4s;
                    }
                }
                &::before {
                    content: '';
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    background-color: rgba($color: #005eff, $alpha: 0.5);
                    opacity: 0;
                    transition: .4s;
                }
            }
        }

        .feed-footer {
            padding: 10px 20px;
            p {
                font-size: .9rem;
                font-weight: 500;
                margin-bottom: 10px;
            }
            a {
                font-size: .8rem;
                color: #00a889;
                text-decoration: none;
            }
            span.date {
                color: rgba($color: #000000, $alpha: 0.8);
                font-size: .8rem;
            }
        }
    }
}

.custom-pagination {
    span {
        color: rgba($color: #000000, $alpha: 0.8);
        font-size: .9rem;
    }
    .page-link {
        color: #9d44c0;
        text-decoration: underline;
        background-color: transparent;
        &.active {
            color: rgba($color: #000000, $alpha: 0.8);
        }
    }
}

section.story-details-header-wrap {
    margin-top: 86px;
    @include small-tab() {
        margin-top: 118px;
    }
    @include big-tab() {
        margin-top: 122px;
    }
    .story-detail-header {
        // border: 1px solid red;
        display: flex;
        flex-direction: column;
        @include small-tab() {
            align-items: center;
            justify-content: space-between;
            flex-direction: row;
        }
        .story-profile {
            display: flex;
            align-items: center;
            figure {
                border-radius: 50%;
                overflow: hidden;
                background-color: #FCC100;
                width: 70px;
                height: 70px;
                padding: 5px;
                @include mobile() {
                    padding: 10px;
                }
                @include big-tab() {
                    width: 90px;
                    height: 90px;
                }
                img {
                    width: 100%;
                    height: 100%;
                    border-radius: 50%;
                    object-fit: cover;
                    object-position: center;
                }
            }
            .profile-text {
                margin-left: 10px;
                @include mobile() {
                    margin-left: 20px;
                }
                h4 {
                    color: $primary-color;
                    margin-bottom: 5px;
                    font-size: 18px;
                    @include mobile() {
                        font-size: 20px;
                    }
                    @include big-tab() {
                        font-size: 23px;
                    }
                }
                span {
                    display: inline-block;
                    color: $text-color;
                    &.highlight {
                        position: relative;
                        color: $text-color;
                        &::before {
                            position: absolute;
                            top: 60%;
                            transform: translateY(-50%);
                            left: -12px;
                            width: 1px;
                            height: 75%;
                            content: '';
                            background-color: $text-color;
                        }
                    }
                }
            }
        }
        .story-stats {
            display: flex;
            margin-top: 20px;
            flex-direction: column;
            @include small-mobile() {
                justify-content: space-between;
                align-items: center;
                flex-direction: row;
            }
            @include small-tab() {
                margin-top: 0;
                justify-content: unset;
            }
            .published-link {
                padding-left: 20px;
                position: relative;
                margin-right: 20px;
                @include big-tab() {
                    margin-right: 100px;
                }
                &::before {
                    content: '';
                    width: 5px;
                    height: 100%;
                    left: 0;
                    top: 50%;
                    transform: translateY(-50%);
                    position: absolute;
                    background-color: $text-color;
                }
                a {
                    text-decoration: none;
                    color: $primary-color;
                    font-weight: 500;
                    font-size: 20px;
                    @include mobile() {
                        font-size: 22px;
                    }
                    @include big-tab() {
                        font-size: 26px;
                    }
                }
            }
            .published-on {
                p {
                    margin-bottom: 0;
                    color: $text-color;
                    font-size: 15px;
                    margin-top: 10px;
                    @include small-mobile() {
                        text-align: right;
                        margin-top: 0;
                    }
                    @include mobile() {
                        font-size: 16px;
                    }
                    span {
                        color: $text-color;
                        font-size: 16px;
                        @include mobile() {
                            font-size: 16px;
                        }
                        @include big-tab() {
                            margin-top: 5px;
                        }
                    }
                }
            }
        }
    }
}

section.story-content {
    background-color: #f3f3ea38;
    background-image: url('../../public/images/homepage/bg-story.svg');
    background-position: center;
    background-size: cover;
    margin-top: 86px;
    @include small-tab() {
        margin-top: 118px;
    }
    @include big-tab() {
        margin-top: 122px;
    }
    .story-content-wrap {
        .story-detail-card {
            border: 5px solid $white-color;
            border-radius: 10px;
            height: 100%;
            background-image: url('../../public/images/homepage/sky-blue-icon-1.svg');
            background-color: #F1F4F7;
            background-size: cover;
            background-position: center;
            padding: 15px;
            @include small-tab() {
                padding: 25px;
            }
            .story-header {
                margin-bottom: 25px;
                display: flex;
                align-items: center;
                @include small-tab() {
                    margin-bottom: 35px;
                }
                figure {
                    width: 65px;
                    height: 65px;
                    border-radius: 50%;
                    overflow: hidden;
                    margin-right: 10px;
                    @include mobile() {
                        margin-right: 20px;
                        width: 70px;
                        height: 70px;
                    }
                    @include small-tab() {
                        width: 80px;
                        height: 80px;
                    }
                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                        object-position: center;
                        border-radius: 50%;
                    }
                }
                .story-text {
                    h4 {
                        text-transform: capitalize;
                        font-size: 22px;
                        color: $primary-color;
                        margin-bottom: 5px;
                        @include small-tab() {
                            font-size: 24px;
                        }
                    }
                    p {
                        text-transform: capitalize;
                        color: $text-color;
                        font-size: 16px;
                        margin-bottom: 0;
                    }
                }
            }
            .story-body {
                .location-theme {
                    h5 {
                        font-size: 18px;
                        font-weight: 400;
                        margin-bottom: 2px;
                    }
                    p {
                        font-size: 18px;
                        color: $primary-color;
                        &.theme-name {
                            margin-top: 12px;
                            padding-left: 10px;
                            padding-bottom: 0px;
                            font-weight: 500;
                            border-left: 4px solid $text-color;
                            font-size: 23px;
                            @include small-tab() {
                                font-size: 25px;
                            }
                        }
                    }
                }
                .share {
                    display: flex;
                    flex-direction: column;
                    @include mobile() {
                        align-items: center;
                        flex-direction: row;
                    }
                    p {
                        margin-bottom: 0;
                    }
                    .links {
                        display: flex;
                        align-items: center;
                        margin-top: 10px;
                        @include mobile() {
                            margin-top: 0px;
                            margin-left: 5px;
                        }
                        a {
                            margin: 0 10px;
                            &:first-child {
                                margin-left: 0;
                            }
                            @include mobile() {
                                &:first-child {
                                    margin-left: 10px;
                                }
                            }
                            svg {
                                font-size: 20px;
                                color: $royal-purple;
                                transition: .4s;
                            }
                            &:hover {
                                svg {
                                    color: $primary-color;
                                }
                            }
                        }
                    }
                }
            }
            .story-footer {
                display: flex;
                align-items: center;
                justify-content: space-between;
                p {
                    margin-bottom: 0;
                    color: $text-color;
                    opacity: .7;
                }
                .views {
                    span {
                        color: #00a889;
                    }
                }
            }
        }
        .story-slider {
            border: 5px solid $white-color;
            border-radius: 10px;
            padding: 15px;
            background-color: #F1F4F7;
            height: 375px;
            display: flex;
            align-items: center;
            flex-direction: column-reverse;
            justify-content: space-between;
            @include mobile() {
                height: 400px;
            }
            @include small-tab() {
                height: 500px;
            }
            @include big-tab() {
                flex-direction: row;
                justify-content: unset;
            }
            .thumbnail-slider-wrap {
                margin-top: 15px;
                width: 100%;
                @include big-tab() {
                    margin-top: 0;
                    margin-right: 15px;
                    width: auto;
                }
                .splide {
                    margin: auto;
                    @include big-tab() {
                        margin: unset;
                    }
                }
                .splide__slide {
                    overflow: hidden;
                    border-radius: 6px;
                    &.is-active {
                        border: 3px solid $primary-color;
                    }
                    figure {
                        height: 100%;
                        position: relative;
                        img {
                            width: 100%;
                            height: 100%;
                            object-fit: cover;
                            object-position: center;
                        }
                        span {
                            position: absolute;
                            top: 50%;
                            left: 50%;
                            transform: translate(-50%,-50%);
                            width: 30px;
                            height: 30px;
                            @include center();
                            border-radius: 50%;
                            background-color: #0000007e;
                            svg {
                                font-size: 20px;
                                color: $white-color;
                            }
                        }
                    }
                }
            }
            .image-preview {
                height: 78%;
                width: 100%;
                @include small-mobile() {
                    height: 70%;
                }
                @include mobile() {
                    height: 78%;
                }
                @include big-tab() {
                    height: 100%;
                }
                .splide {
                    height: 100%;
                    .splide__track {
                        height: 100%;
                        ul {
                            li {
                                height: 100%;
                                background-color: $white-color;
                                border-radius: 6px;
                                overflow: hidden;
                                figure {
                                    height: 100%;
                                    width: 100%;
                                    img {
                                        width: 100%;
                                        height: 100%;
                                        object-position: center;
                                        object-fit: cover;
                                        @include small-tab() {
                                            object-fit: contain;
                                            transform: scale(1.02);
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
section.story-text-wrap {
    h4 {
        margin-bottom: 30px;
        font-size: 24px;
        @include mobile() {
            font-size: 26px;
        }
        @include small-tab() {
            font-size: 28px;
        }
        @include big-tab() {
            font-size: 30px;
        }
        @include desktop() {
            font-size: 36px
        }
    }
    p {
        color: $text-color;
        font-size: 16px;
        @include small-tab() {
            font-size: 18px;
        }
    }
    .story-content {
        color: $text-color !important;
        font-size: 16px;
        @include small-tab() {
            font-size: 18px;
        }
    }
}

section.inspiring-stories {
    background-color: #ffc000;
    background-image: url('../../public/images/common/bg-inspire.jpg');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    .headings {
        p {
            font-size: 1.2rem;
        }
    }
    .stories-slider {
        .splide__track {
            padding-top: 50px;
        }
        .splide__pagination {
            bottom: -2.5em;
            @include small-tab() {
                bottom: -3.5em;
            }
            li {
                button {
                    width: 12px;
                    height: 12px;
                    margin: 0 6px;
                    &.is-active {
                        transform: unset;
                        background-color: $white-color;
                    }
                }
            }
        }
    }
}

section.tell-stories {
    background-size: cover;
    background-position: top;
    background-repeat: no-repeat;
    background-image: url('../../public/images/story-details/bg.jpg');
    position: relative;
    padding: 180px 0 20px;
    @include big-tab() {
        padding: 250px 0;
    }
    &::before {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        content: '';
        background-color: rgba($color: #000, $alpha: 0.3);
        z-index: 0;
    }
    .container {
        position: relative;
        z-index: 1;
    }
    .headings {
        margin-bottom: 0;
        .h4 {
            color: $white-color;
            font-size: 1.4rem;
            @include big-tab() {
                font-size: 2.2rem;
            }
        }
        p {
            color: $white-color;
        }
        .btn-custom {
            text-decoration: none;
            .text {
                color: #9C44BF !important;
                background-color: #79DBF6;
            }
            .icon {
                background-color: #79DBF6;
            }
        }
    }
    .share-wrapper {
        .share-icon {
            width: 40px;
            height: 40px;
            background-color: $white-color;
            transition: .4s;
            @include center();
            svg {
                font-size: 1.4rem;
                path {
                    fill: $royal-purple;
                }
            }
            &:not(:first-of-type) {
                margin-left: 10px;
            }
            &:hover {
                background-color: $royal-purple;
                svg {
                    path {
                        fill: $white-color;
                    }
                }
            }
        }
    }
}

.not-found {
    margin-top: 86px;
    @include small-tab() {
        margin-top: 118px;
    }
    @include big-tab() {
        margin-top: 122px;
    }
    .headings {
        span {
            color: #1f36c7;
            &.h1 {
                font-size: 1.6rem;
                margin-bottom: 15px;
                @include small-tab(){
                    font-size: 3rem;
                    margin-bottom: 30px;
                }
            }
        }
        p {
            color: $text-color;
            font-size: 1rem;
            @include small-tab() {
                font-size: 1.2rem;
            }
        }
    }
    .btn-custom {
        span {
            &.text {
                background-color: #77ddf5;
                color: #7705bc !important;
            }
            &.icon {
                background-color: #77ddf5;
                margin-left: 0;
                margin-right: 2px;
                svg {
                    color: $white-color !important;
                    transform: scaleX(-1);
                }
            }
        }
    }
}

.Toastify__toast-body {
    font-family: 'UnileverShilling', serif;
    font-size: .95rem;
}


.breadcrumb {
    padding: 10px 0 20px;
    background-color: #1f36c7;
    width: 100%;
    top: 0;
    left: 0;
    margin-bottom: 0;
    margin-top: 86px;
    @include small-tab() {
        margin-top: 118px;
    }
    @include big-tab() {
        margin-top: 122px;
    }
    ul {
        li {
            padding-right: 10px;
            position: relative;
            &:not(:last-of-type) {
                &::after {
                    content: "";
                    display: inline-block;
                    width: 0;
                    height: 0;
                    -webkit-margin-start: 0.625rem;
                    margin-inline-start: 0.625rem;
                    border-color: transparent transparent transparent #77ddf5;
                    border-style: solid;
                    border-width: 4px 0 4px 6.9px;
                    transform: scaleX(1);
                }
            }
            a {
                color: $white-color;
                font-size: .8rem;
            }
        }
    }
}

section.page-header {
    background-color: $white-color;
    position: relative;
    padding: 80px 0;
    overflow: hidden;
    .page-header-headings {
        h1 {
            color: #1f36c7
        }
        p {
            color: #1f36c7;
        }
    }
    .container {
        position: relative;
        z-index: 1;
    }
    img {
        &.top-left {
            position: absolute;
            top: 0;
            left: 0;
            width: 200px;
            z-index: 0;
        }
        &.top-right {
            position: absolute;
            top: 0;
            right: 0;
            width: 250px;
            z-index: 0;
        }
    }
}

section.faq-accordion {
    .accordion {
        .accordion-item {
            padding: 10px 0;
            border: none;
            &:not(:first-of-type) {
                border-top: 1px solid #d3d3c8;
            }
            h2 {
                button {
                    padding: 20px 40px 20px 0px;
                    color: $primary-color;
                    font-size: 1rem;
                    font-weight: 500;
                    margin-right: 20px;
                    @include small-tab() {
                        font-size: 1.3rem;
                        font-weight: 700;
                    }
                    &:not(.collapsed) {
                        background-color: unset;
                        box-shadow: unset;
                    }
                    &::after {
                        content: "";
                        position: absolute;
                        inset-block-start: calc(50% - 4.35px);
                        inset-inline-end: 22.916663px;
                        width: 0;
                        height: 0;
                        border-color: #005eff transparent transparent;
                        border-style: solid;
                        border-width: 8.7px 5px 0;
                        transition: all .2s cubic-bezier(.25,.46,.45,.94);
                    }
                }
            }
            .accordion-body {
                padding: 10px 0 20px;
                color: #73706e;
                font-size: .9rem;
                @include small-tab() {
                    font-size: 1rem;
                }
            }
        }
    }
}

section.faq-cta {
    background-image: url('../../public/images/cta/cta-bg.jpg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    .h5 {
        font-size: 2rem;
        color: $primary-color;
    }
    a.btn-custom {
        span.icon {
            svg {
                path {
                    fill: $primary-color;
                }
            }
        }
    }
}

.scroll-down {
    position: absolute;
    bottom: 15px;
    left: 50%;
    transform: translateX(-50%);
    @include mobile() {
        bottom: 20px;
    }
    a {
        color: $white-color;
        text-decoration: none;
        span {
            position: absolute;
            bottom: 30px;
            left: 50%;
            width: 30px;
            height: 50px;
            margin-left: -15px;
            border: 2px solid #fff;
            border-radius: 50px;
            box-sizing: border-box;
            &::before {
                position: absolute;
                top: 10px;
                left: 50%;
                content: '';
                width: 6px;
                height: 6px;
                margin-left: -3px;
                background-color: #fff;
                border-radius: 100%;
                -webkit-animation: sdb10 2s infinite;
                animation: sdb10 2s infinite;
                box-sizing: border-box;
            }
        }
    }
}

section.auth-content {
    background-color: #f6f5ed;
    position: relative;
    z-index: 10;
    margin-top: 86px;
    @include small-tab() {
        margin-top: 118px;
    }
    @include big-tab() {
        margin-top: 122px;
    }
    img.top-left {
        position: absolute;
        top: 0;
        left: 0;
        width: 100px;
        z-index: 0;
        display: none;
        z-index: -1;
        pointer-events: none;
        @include big-tab() {
            display: block;
            width: 200px;
        }
        @include desktop() {
            width: 300px;
        }
    }

    img.top-right {
        position: absolute;
        top: 0;
        right: 0;
        width: 100px;
        z-index: -1;
        display: none;
        pointer-events: none;
        
        @include big-tab() {
            width: 200px;
            display: block;
        }
        @include desktop() {
            width: 300px;
        }
    }
}

section.my-stories {
    background-color: #f7f7ef;
    margin-top: 86px;
    @include small-tab() {
        margin-top: 118px;
    }
    @include big-tab() {
        margin-top: 122px;
    }
    .headings {
        color: $primary-color;
    }
    .my-story-card {
        border: 4px solid $white-color;
        background-color: #f6f8fa;
        border-radius: 17px;
        padding: 20px;
        li {
            margin-bottom: 15px;
            display: flex;
            flex-direction: column;
            &:last-child {
                margin-bottom: 0px;
            }
            span {
                color: $text-color;
                &.key {
                    color: $royal-purple;
                    font-weight: 500;
                    position: relative;
                    margin-right: 5px;
                    @include small-tab() {
                        display: inline-block;
                        width: 130px;
                    }
                    &::after {
                        content: ' -';
                        @include small-tab() {
                            position: absolute;
                            top: 50%;
                            right: 0;
                            transform: translateY(-50%);
                        }
                    }
                }
            }
            @include small-tab() {
                flex-direction: row;
            }
        }
        .edit-story {
            text-align: right;
            display: block;
            margin-top: 30px;
            text-decoration: none;
            font-size: 14px;
            color: #00a889;
            svg {
                font-size: 18px;
                margin-left: 6px;
            }
        }
        figure {
            border: 4px solid #cee0f2;
            border-radius: 12px;
            overflow: hidden;
            @include center();
            img._placeholder {
                max-width: 100px;
                filter: grayscale(100%);
            }
        }
        .btn-custom {
            span {
                font-size: 16px;
                padding: 6px 8px;
                background-color: $royal-purple;
                color: $white-color;
                &:first-child {
                    margin-right: 2px;
                }
            }
        }
    }
}

section.email-verified-wrap {
    .verified-success {
        background-color: $white-color;
        padding: 62px 20px;
        @include center();
        flex-direction: column;
        .icon {
            margin-bottom: 15px;
            svg {
                font-size: 60px;
                color: #0CD8AA;
            }
        }
        h2 {
            color: $primary-color;
            text-align: center;
        }
    }
}

.consent-modal {
    .modal-dialog {
        max-width: 700px;
    }
    .modal-content {
        border-radius: 0;
        background-image: url('../../public/images/homepage/sky-blue-icon-1.svg');
        background-color: #F1F4F7;
        background-size: cover;
        background-position: center;
       
    }
}

.consent-form-wrap {
    .headings {
        color: $primary-color;
    }
    .consent-modal-wrap {
        background-color: $white-color;
        padding: 40px;
        h4 {
            font-size: 20px;
        }
        p {
            color: $text-color;
            &.primary {
                color: $primary-color;
                margin-bottom: 20px;
                margin-top: 30px;
            }
        }
        button {
            border: 0;
            outline: 0;
            background-color: #00a889;
            padding: 3px 8px;
            color: $white-color;
            &.grey {
                background-color: $text-color;
            }
        }
    }
}

section.edit-story {
    margin-top: 86px;
    background-color: #f6f8fa;
    @include small-tab() {
        margin-top: 118px;
    }
    @include big-tab() {
        margin-top: 122px;
    }
    .headings {
        .h4 {
            color: $primary-color;
        }
    }
    .you-form-wrapper {
        .form-content {
            min-height: unset;
            border-left: unset !important;
        }
    }
}

.no-stories-wrap {
    border: 4px solid $white-color;
    background-color: #f6f8fa;
    margin-top: 0;
    padding: 15px;
    border-radius: 10px;
    @include small-tab() {
        padding: 30px;
    }
    figure {
        @include big-tab() {
            width: 400px;
            margin: auto;
        }
    }
}

.pagination-wrap {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    ._pagination {
        display: flex;
        align-items: center;
        button {
            border: 0;
            outline: 0;
            background-color: $primary-color;
            width: 40px;
            height: 40px;
            @include center();
            svg {
                font-size: 22px;
                color: $white-color;
            }
        }
        span {
            width: 40px;
            height: 40px;
            @include center();
            margin: 0 5px;
            background-color: #005eff33;
        }
    }
}

.play-story-audio {
    .audio-wrapper {
        border: 5px solid $white-color;
        border-radius: 30px;
        audio::-webkit-media-controls-panel {
            background-color: #F1F4F7;
            outline: #F1F4F7 solid 2px;
            color: rgba($color: #aaa, $alpha: 0.65);
        }

        audio::-webkit-media-controls-play-button {
            color: rgba($color: #aaa, $alpha: 0.65);
        }

        audio::-webkit-media-controls-current-time-display,
        audio::-webkit-media-controls-time-remaining-display,
        audio::-webkit-media-controls-timeline {
            color: rgba($color: #aaa, $alpha: 1);
        }

        span {
            color: rgba($color: #aaa, $alpha: 0.65);
        }

        svg {
            font-size: 1.4rem;

            path {
                fill: rgba($color: #aaa, $alpha: 0.65);
            }
        }
    }
}